import React from 'react';
import { Link } from 'react-router-dom';

import style from './AllTransactions.module.scss';

export const AllTransactions = (): JSX.Element => (
  <div className={style.wrapper}>
    <h2 className={style.title}>Все транзакции</h2>
    <nav>
      <ul className={style.navWrapper}>
        <li>
          <Link className={style.navItem} to={'./banks'}>
            Отчёты банков
          </Link>
        </li>
        <li>
          <Link className={style.navItem} to={'./summary'}>
            Сводная таблица
          </Link>
        </li>
        <li>
          <Link className={style.navItem} to={'./total'}>
            Итоговая таблица
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);
