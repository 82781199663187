export const getDate = (date: string): string =>
  new Date(date)
    .toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');

export interface ITotalReportValues {
  start_date: Date;
  end_date: Date;
  owner_id?: number;
}

export interface ITotalReportErrors {
  start_date: boolean;
  end_date: boolean;
  owner_id: boolean;
}

export const TotalReportValues = {
  StartDate: 'start_date',
  EndDate: 'end_date',
  owner: 'owner',
};

export const initialValues = {
  [TotalReportValues.StartDate]: null,
  [TotalReportValues.EndDate]: null,
} as unknown as ITotalReportValues;
// TOOD: убрать as, посмотреть что не так с типами

export const validateGetTotalReport = (values: ITotalReportValues): object => {
  const errors = {} as ITotalReportErrors;

  if (!values.start_date) {
    errors.start_date = true;
  }

  if (!values.end_date) {
    errors.end_date = true;
  }

  return errors;
};
