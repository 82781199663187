import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { ISupermixListItem } from 'app/api/services/supermix/types';
import { ISupermixProfitValues } from 'pages/Navbar/Dashboards/SupermixProfitId/helpers';
import { SelectWithOptions } from 'widgets';
import { SelectWrapper } from 'widgets/SelectWrapper';

import style from './SupermixSelect.module.scss';

export const SupermixSelect = ({
  field,
  isError,
  supermixes,
}: {
  field: string;
  isError?: boolean;
  supermixes: ISupermixListItem[];
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [supermix, setSupermix] = useState<{ id: number; name: string }>();

  const { setFieldValue } = useFormikContext<ISupermixProfitValues>();

  const handleTypeSelect = (option: { id: number; name: string }) => {
    setSupermix(option);
    setIsOpen(false);
    setIsValueVisible(true);
  };

  useEffect(() => {
    if (supermix?.id) {
      setFieldValue(field, supermix.id);
    }
  }, [field, setFieldValue, supermix?.id]);

  return (
    <SelectWrapper
      isOpen={isOpen}
      isValueVisible={isValueVisible}
      setIsOpen={setIsOpen}
      title="Супермикс"
      value={supermix?.name}
      disabled={false}
      className={style.select}
      isError={isError}
    >
      <SelectWithOptions
        searchEntity="supermix"
        options={supermixes}
        onChange={handleTypeSelect}
        menuIsOpen={true}
      />
    </SelectWrapper>
  );
};
