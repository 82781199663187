export interface ISupermixProfitValues {
  supermix_id: string;
  period: string;
}
export interface IValidateSupermixProfit {
  period: boolean;
  supermix_id: boolean;
}

export const getDate = (date: string): string =>
  new Date(date)
    .toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'numeric',
    })
    .split('.')
    .reverse()
    .join('-');

export const validateGetSupermixProfit = (
  values: ISupermixProfitValues
): object => {
  const errors = {} as IValidateSupermixProfit;

  if (!values.supermix_id) {
    errors.supermix_id = true;
  }

  if (!values.period) {
    errors.period = true;
  }

  return errors;
};

export const SupermixProfitFields = {
  supermix_id: 'supermix_id',
  period: 'period',
};

export const initialValues = {
  [SupermixProfitFields.supermix_id]: '',
  [SupermixProfitFields.period]: '',
} as unknown as ISupermixProfitValues;
// TOOD: убрать as, посмотреть что не так с типами

export const RefreshIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"
    />
    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466" />
  </svg>
);
