import * as Yup from 'yup';

import { errorMessages } from 'pages/Navbar/Dashboards/helpers';

export const fields = {
  Owner: 'owner',
  EndMonth: 'end_month',
  StartMonth: 'start_month',
};

export const validationSchema = Yup.object().shape({
  start_month: Yup.date().nullable().required(errorMessages.start_required),
  end_month: Yup.date()
    .nullable()
    .required(errorMessages.end_required)
    .min(Yup.ref('start_month'), errorMessages.end_before_start),
  owner: Yup.object().shape({ id: Yup.number().nullable() }),
});
