import React, { useState } from 'react';

import { Copy, CopyActive } from '../../../shared/icons';

import style from './CopyButton.module.scss';

interface IProps {
  text: string;
}

export const CopyButton = React.memo(({ text }: IProps): JSX.Element => {
  const [btnClicked, setBtnClicked] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(text);
    setBtnClicked(true);
  };

  return (
    <button className={style.copyButton} type="button" onClick={copyText}>
      {btnClicked ? <CopyActive /> : <Copy />}
    </button>
  );
});
