import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line max-len
import {
  useGetTagByIdForCompilationsQuery,
  useUpdateTagForCompilationMutationMutation,
} from '../../../../../../app/api/services/tadForCompilations/tagForCompilations';
import { Characteristics, Router } from '../../../../../../components';
import { errorToast, successToast } from '../../../../../../utils';
import { getDiffObjects } from '../../../../../../utils/diffObjects';
import { Header, Loader, SaveButton } from '../../../../../../widgets';
import { createObjectToSend } from '../../../Author/CreateAuthor/AuthorFields';
import { DescriptiveParameters } from '../../../Tag/EditTag/EditTagComponents';
import { TagsForCompilationsFields } from '../../TagsForCompilationsFields';
import { TagsForCompilationsMainParameters } from '../MainParameters';

import style from '../../../PlaylistCompilation/CreatePlaylistCompilation/ui/CreatePlaylistCompilation.module.scss';

const EditTagsForCompilations = (): JSX.Element => {
  const [isToast, setIsToast] = useState(false);

  const { id } = useParams();
  const tag_id = Number(id);

  const {
    data: items,
    isLoading,
    refetch,
    isSuccess,
  } = useGetTagByIdForCompilationsQuery(tag_id, {
    refetchOnMountOrArgChange: true,
  });

  const [editTag, { isError: isErrorUpdate }] =
    useUpdateTagForCompilationMutationMutation();

  const formikInitialValues = {
    [TagsForCompilationsFields.Name]: items?.response?.name
      ? items?.response?.name
      : '',
    [TagsForCompilationsFields.Description]: items?.response?.description
      ? items?.response?.description
      : '',
    [TagsForCompilationsFields.Is_active]: items?.response?.is_active
      ? items?.response?.is_active
      : false,
  };

  const submitHandle = async (values) => {
    const diff = getDiffObjects(formikInitialValues, values);
    const valuesToSend = createObjectToSend(diff, formikInitialValues, values);

    await editTag({ tag_id, payload: { ...valuesToSend } })
      .unwrap()
      .then(() => {
        refetch();
      });
  };

  useEffect(() => {
    successToast({
      success: isSuccess,
      message: 'Тег успешно изменен',
      setIsToast,
    });
  }, [isSuccess]);

  useEffect(() => {
    errorToast(isErrorUpdate, 'Не удалось изменить тег', setIsToast);
  }, [isErrorUpdate]);

  return isLoading && !isToast ? (
    <Loader />
  ) : (
    <Formik initialValues={formikInitialValues} onSubmit={submitHandle}>
      <Form
        className={style.form}
        onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
      >
        <div className={style.container}>
          <div className={style.headerWrapper}>
            <Header label="Редактирование тега для подборок" />
            <SaveButton disabled={false} />
          </div>
          <div className={style.lastBlock}>
            <DescriptiveParameters
              tags={items?.response?.tags}
              link={'/' + Router.TagDirectoryForCompilations}
              textLink="Перейти в каталог тегов для подборок"
              isObligatory={false}
              titleDescriptions="Тема:"
              classNameTags={style.tagsCompilations}
              placeholderField="Введите описание"
            />
            <div className={style.paramsWrapper}>
              <TagsForCompilationsMainParameters entity={items?.response} />
              <Characteristics
                titleUUID="UUID подборки:"
                titleDateCreated="Дата создания подборки:"
                UUIDDescription={items?.response?.uuid}
                dateCreatedDescription={items?.response?.created_at}
                classNameWrapper={style.characteriscticsBlock}
              />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default EditTagsForCompilations;
