import * as Yup from 'yup';

import { errorMessages } from 'pages/Navbar/Dashboards/helpers';

export const validationSchema = Yup.object().shape({
  start_date: Yup.date().nullable().required(errorMessages.start_required),
  end_date: Yup.date()
    .nullable()
    .required(errorMessages.end_required)
    .min(Yup.ref('start_date'), errorMessages.end_before_start),
  owner: Yup.object().shape({ id: Yup.number().nullable() }),
});

export const fields = {
  Owner: 'owner',
  EndDate: 'end_date',
  StartDate: 'start_date',
};
