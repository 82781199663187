import { useFormikContext } from 'formik';
import { ReactNode } from 'react';

import {
  DateSelect,
  EntitySelect,
  MonthsSelect,
} from 'pages/Navbar/Dashboards/DashboradsComponents';
import { TOption } from 'shared/types/typesOptionsSelect';
import { DownloadSpreadsheet } from 'widgets';

import style from './Bank.module.scss';
interface IProps {
  title: string;
  icon: ReactNode;
  owners: TOption;
  field: string;
  downloadHandler: () => void;
  downloadDisabled?: boolean;
  bank: 'apple' | 'tbank';
}

export const Bank = ({
  title,
  icon,
  owners,
  field,
  downloadHandler,
  downloadDisabled,
  bank,
}: IProps): JSX.Element => {
  const { errors } = useFormikContext<{ owner: number }>();

  return (
    <div className={style.bank}>
      <div className={style.bankTitle}>
        {icon}
        <div>{title}</div>
      </div>
      <div className={style.bankRight}>
        <div className={style.bankSelects}>
          {owners && (
            <EntitySelect
              field={field}
              options={owners}
              title="Правообладатель"
              isDisabled={false}
              isError={!!errors.owner}
              searchEntity={'owners'}
            />
          )}
          {bank === 'apple' && <MonthsSelect />}
          {bank === 'tbank' && <DateSelect dateFormat="yyyy.MM.dd" />}
        </div>
        <div className={style.bankButtons}>
          {/* <CreateButton
            description="Обновить базу"
            icon={<RefreshIcon />}
            onClick={() => null}
            type="reverse"
          /> */}
          <DownloadSpreadsheet
            onClick={downloadHandler}
            disabled={downloadDisabled}
          />
        </div>
      </div>
    </div>
  );
};
