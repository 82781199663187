import cn from 'classnames';
import { Field, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { TagCatalog } from '../../../../../../../../app/api/services/tagCatalog/types';
import {
  ContainerWrapper,
  Tag,
  TextArea,
  TitleWithDescription,
} from '../../../../../../../../widgets';
import { IValidateTag, TagFields } from '../../../TagFields';

import style from './DescriptiveParameters.module.scss';

interface IProps {
  tags: TagCatalog[];
  link: string;
  textLink: string;
  isObligatory: boolean;
  titleDescriptions: string;
  classNameTags?: string;
  placeholderField: string;
}

export const DescriptiveParameters = ({
  tags,
  link,
  textLink,
  isObligatory,
  titleDescriptions,
  classNameTags,
  placeholderField,
}: IProps): JSX.Element => {
  const { values } = useFormikContext<TagCatalog>();
  const { errors } = useFormikContext<IValidateTag>();

  const level = {
    '1': 'I (высшая категория)',
    '2': 'II (высокочастотник)',
    '3': 'III (среднечастотник)',
    '4': 'IV (низкочастотник)',
  };

  return (
    <div className={style.gridItem1}>
      <div className={style.wrapper}>
        <div className={style.headerWrapper}>
          <ContainerWrapper title="Описательные параметры">
            <div className={style.blockWrapper}>
              <div className={style.autoName}>
                <TitleWithDescription
                  title="Название"
                  description={values.name}
                  isObligatory={isObligatory}
                />
              </div>
              <div className={style.inputTextArea}>
                <Field
                  name={TagFields.Description}
                  value={values.description}
                  placeholder={placeholderField}
                  title="Описание"
                  component={TextArea}
                  isError={!!errors?.description}
                  isObligatory={isObligatory}
                />
              </div>
              <div className={cn(style.tags, classNameTags)}>
                <TitleWithDescription
                  title={titleDescriptions}
                  description={level[values.level]}
                  isObligatory={isObligatory}
                />
                <div className={style.tagsBlock}>
                  {!isEmpty(tags) &&
                    tags
                      .filter((tag) => tag.level < values.level)
                      .map((item, index) => (
                        <Tag
                          value={item.name}
                          key={index}
                          category="tagGroup"
                          size="normal"
                        />
                      ))}
                  <Tag
                    value={values.name}
                    key={0}
                    category="low"
                    size="normal"
                  />
                </div>
              </div>
              <div className={style.link}>
                <Link to={link}>{textLink}</Link>
              </div>
            </div>
          </ContainerWrapper>
        </div>
      </div>
    </div>
  );
};
