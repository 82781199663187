import cn from 'classnames';
import { useFormikContext } from 'formik';
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { Clip } from '../../../app/api/services/clips/types';
import { Playlist } from '../../../app/api/services/playlists/types';
import { Router } from '../../../components';
import { Modal } from '../../../components/Modal';
import { Plus, Point, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { getTheIdArray } from '../../../utils';
import { ClickShowContainer } from '../../ClickShowContainer';
import { SelectWithOptions } from '../../SelectWithOptions';
import { Separator } from '../../Separator';
import { TextWithTooltip } from '../../TextWithTooltip';
import { ObligatoryField } from 'widgets';

import style from './AddManyEntityWithListSimple.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  options: Playlist[] | Clip[];
  field: string;
  items?: Playlist[];
  label: string;
  linkToEditEntity: string;
  isError?: boolean;
  setPlaylistsOffset: Dispatch<SetStateAction<number>>;
  totalCount: number;
  setAddedPlaylists: Dispatch<SetStateAction<number[]>>;
  setDeletedPlaylists: Dispatch<SetStateAction<Playlist[]>>;
  deletedPlaylists: number[];
  addedPlaylists: Playlist[];
  isObligatory?: boolean;
}

export const AddManyEntityWithListSimple = ({
  searchEntity,
  items,
  options,
  label,
  field,
  linkToEditEntity,
  isError = false,
  setPlaylistsOffset,
  totalCount,
  setDeletedPlaylists,
  setAddedPlaylists,
  deletedPlaylists,
  addedPlaylists,
  isObligatory,
}: IProps): JSX.Element => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { setFieldValue } = useFormikContext();

  const [allSelectedOptions, setAllSelectedOptions] = useState<Playlist[]>();

  useEffect(() => {
    if (items) {
      if (!allSelectedOptions) return;
      setAllSelectedOptions((prev) => [
        ...prev.filter((el) => !deletedPlaylists.includes(el.id)),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, deletedPlaylists, addedPlaylists]);

  useEffect(() => {
    if (items) {
      setAllSelectedOptions([...addedPlaylists, ...items]);
    }
  }, [addedPlaylists, items]);

  const handleTypeSelect = (option) => {
    setSelectedOption(null);

    const isInArray = !!allSelectedOptions.find(
      (item) => item?.id === option?.id
    );

    if (isInArray || option === null) {
      return;
    } else {
      setAddedPlaylists((prev) => [...prev, option]);
      setShow(false);
    }
  };

  const clickHandle = (e: SyntheticEvent) => {
    e.preventDefault();
    setShow(true);
  };

  const deleteHandler = (clickedOption) => {
    setDeletedPlaylists((prev) => [...prev, clickedOption.id]);
  };

  useEffect(() => {
    setFieldValue(
      field,
      getTheIdArray(allSelectedOptions?.map((item) => item))
    );

    if (field === 'elements') {
      const arrayOfOptions = allSelectedOptions?.map((element) => ({
        id: element.id,
      }));

      setFieldValue(field, { [searchEntity]: arrayOfOptions });
    }
  }, [allSelectedOptions, field, searchEntity, setFieldValue]);

  const renderOptions = (options) => (
    <div className={style.selectedOptions}>
      {options?.map((option) => (
        <div key={option?.id} className={cn(style.option)}>
          <div className={style.point}>
            <Point />
          </div>
          <Link
            className={style.name}
            to={`${linkToEditEntity}/${Router.Edit}/${option?.id}`}
          >
            <TextWithTooltip text={option?.name} />
          </Link>
          <button
            type="button"
            onClick={() => deleteHandler(option)}
            className={style.deleteButton}
          >
            <Trash />
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerAndPlus}>
          <h3 className={isError ? style.labelError : style.label}>
            {label} {isObligatory && <ObligatoryField />}
          </h3>
          <button className={style.add} onClick={(e) => clickHandle(e)}>
            <Plus />
          </button>
        </div>
      </div>

      <Separator />

      {show && (
        <ClickShowContainer
          dropdown={show}
          setDropdown={setShow}
          className={cn(style.select)}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
            value={selectedOption}
          />
        </ClickShowContainer>
      )}

      <div>
        <div className={style.selectedOptions}>
          {renderOptions(allSelectedOptions?.slice(0, 9))}
        </div>

        {allSelectedOptions?.length >= 9 && (
          <button
            type="button"
            className={style.buttonShowAll}
            onClick={() => setModalOpen(true)}
          >
            Показать все
          </button>
        )}

        <Modal active={modalOpen} setActive={setModalOpen} title="Плейлисты">
          <div id="scrollableDiv" className={style.modal}>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={totalCount}
              hasMore={true}
              next={() => setPlaylistsOffset((prev) => prev + 50)}
              loader={false}
              className={style.scrollContainer}
            >
              {renderOptions(allSelectedOptions)}
            </InfiniteScroll>
          </div>
        </Modal>
      </div>
    </div>
  );
};
