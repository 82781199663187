import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { IEntityMerging } from '../../../app/global/types';
import { Plus, Trash } from '../../../shared/icons';
import { SearchEntity } from '../../../store/slices/selectSlice';
import { getTheIdArray } from '../../../utils';
import { ClickShowContainer } from '../../ClickShowContainer';
import { Img } from '../../Img';
import { SelectWithOptions } from '../../SelectWithOptions';
import { TextWithTooltip } from '../../TextWithTooltip';

import style from './AddManyEntityWithAvatar.module.scss';

interface IProps {
  searchEntity: SearchEntity;
  label: string;
  options: IEntityMerging[];
  field: string;
  items: IEntityMerging[];
  className?: string;
  margin?: string;
}

export const AddManyEntityWithAvatar = ({
  searchEntity,
  label,
  options,
  field,
  items,
  className,
  margin,
}: IProps): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const [show, setShow] = useState(false);

  const [allSelectedOptions, setAllSelectedOptions] = useState(
    items?.[0] ? items : []
  );

  const clickHandle = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleTypeSelect = (option) => {
    setShow(false);
    const isInArray = !!allSelectedOptions.find(
      (item) => item?.id === option?.id
    );

    if (isInArray || option === null) {
      return;
    }

    setAllSelectedOptions((prevState) => [...prevState, option]);
  };

  useEffect(() => {
    setFieldValue(field, getTheIdArray(allSelectedOptions));
  }, [allSelectedOptions, field, setFieldValue]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    setIsDeleting(!isDeleting);
  };

  const handleEntityClick = (entity) => {
    if (isDeleting) {
      setSelectedEntities((prevSelectedEntities) => {
        if (prevSelectedEntities.includes(entity)) {
          return prevSelectedEntities.filter((e) => e !== entity);
        } else {
          return [...prevSelectedEntities, entity];
        }
      });
    }
  };

  const handleDeleteSelected = (e) => {
    e.preventDefault();
    const filteredArray = allSelectedOptions.filter(
      (option) => !selectedEntities.some((entity) => entity.id === option.id)
    );

    setAllSelectedOptions(filteredArray);

    setSelectedEntities([]);
    setIsDeleting(false);
  };

  return (
    <div className={cn(style.container, className)}>
      <div className={style.buttonsWrapper}>
        <div className={style.labelAndButton}>
          <h3 className={cn(style.label, margin)}>{label}</h3>

          <button className={style.add} onClick={(e) => clickHandle(e)}>
            <Plus />
          </button>
        </div>
        <div className={style.labelAndButton}>
          <button
            className={cn(style.trash, { [style.deleting]: isDeleting })}
            onClick={(e) =>
              isDeleting ? handleDeleteSelected(e) : handleDeleteClick(e)
            }
          >
            <Trash color={isDeleting ? '#F00' : '#7D7D7D'} />
          </button>

          {/* {isDeleting && (
          //TODO уточнить как именно должны подтверждать удаление сущностей
          <button onClick={handleDeleteSelected}>Delete Selected</button>
        )} */}
          {/* {isDeleting && (
            //TODO уточнить как именно должны подтверждать удаление сущностей
            <button onClick={handleDeleteSelected}>Delete Selected</button>
          )} */}
        </div>
      </div>

      {show && (
        <ClickShowContainer
          dropdown={show}
          setDropdown={setShow}
          className={cn(style.select)}
        >
          <SelectWithOptions
            searchEntity={searchEntity}
            options={options}
            onChange={handleTypeSelect}
          />
        </ClickShowContainer>
      )}

      <div className={style.selectedOptions}>
        {allSelectedOptions.map((option) => (
          <div
            key={option.id}
            className={cn(
              style.option,
              selectedEntities.includes(option) ? style.selected : ''
            )}
            onClick={() => handleEntityClick(option)}
          >
            <Img className={style.image} circle src={option?.image?.url} />
            <TextWithTooltip className={style.name} text={option.name} />
          </div>
        ))}
      </div>
    </div>
  );
};
