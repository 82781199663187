import * as Yup from 'yup';

import { errorMessages } from '../helpers';

export const validationSchemaGetSummaryReport = Yup.object().shape({
  start_date: Yup.date()
    .nullable()
    .required(errorMessages.start_required)
    .max(new Date(), errorMessages.future_time),

  end_date: Yup.date()
    .nullable()
    .required(errorMessages.end_required)
    .max(new Date(), errorMessages.future_time)
    .min(Yup.ref('start_date'), errorMessages.end_before_start),
});
