import { FeedGroup } from '../../../../../../../../app/api/services/feedGroups/types';
import { Feed } from '../../../../../../../../app/api/services/feeds/types';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';
import {
  deleteRubric,
  IRubricState,
} from '../../../../../../../../store/slices/rubricSlice';
import { MenuOption } from '../../../../../../../../widgets';
import { EditRubricFrame } from '../../AddRubricFrame';
import { FlatFeedGroupView } from '../../FlatFeedGroupView';
import { FlatFeedViewInRubric } from '../../FlatFeedViewInRubric';

import style from './RubricFrameElements.module.scss';

export const RubricFrameElements = (): JSX.Element => {
  const elements = useAppSelector(
    (state) => (state.rubricReducer as IRubricState).list
  );
  const dispatch = useAppDispatch();

  return (
    <div className={style.wrapper}>
      {elements?.map((element) =>
        element?.entity_type === 'feed' ? (
          <>
            <div className={style.inputWrapper} key={element.position}>
              <FlatFeedViewInRubric
                title="Лента:"
                feed={element.entity as Feed}
                className={style.input}
              />

              <MenuOption
                onDelete={() => dispatch(deleteRubric(element.position))}
                position="right"
                isTransparent={true}
              >
                <EditRubricFrame elementToEdit={element} position="right" />
              </MenuOption>
            </div>
          </>
        ) : (
          <div key={element.position} className={style.inputWrapper}>
            <FlatFeedGroupView
              title="Лодочка:"
              feedGroup={element.entity as FeedGroup}
              className={style.input}
            />

            <MenuOption
              onDelete={() => dispatch(deleteRubric(element.position))}
              position="right"
              isTransparent={true}
            >
              <EditRubricFrame elementToEdit={element} position="right" />
            </MenuOption>
          </div>
        )
      )}
    </div>
  );
};
