import cn from 'classnames';
import { FieldInputProps } from 'formik';

import { ObligatoryField } from 'widgets/ObligatoryField';

import style from './TextArea.module.scss';

interface IProps {
  field: FieldInputProps<string>;
  placeholder?: string;
  title?: string;
  isError?: boolean;
  className?: string;
  isObligatory?: boolean;
}

export const TextArea = ({
  field,
  placeholder,
  title = 'Описание',
  isError = false,
  className,
  isObligatory = false,
}: IProps): JSX.Element => (
  <label className={cn(style.container, className)} data-testid="label">
    <span className={cn(style.label, { [style.errorLabel]: isError })}>
      {title} {isObligatory && <ObligatoryField />}
    </span>
    <textarea
      className={cn(style.textarea, { [style.errorTextarea]: isError })}
      {...field}
      placeholder={placeholder ? placeholder : ''}
    />
  </label>
);
