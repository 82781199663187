/* eslint-disable max-lines-per-function */
import {
  IResponseGetTopicCatalog,
  IResponseGetTopicCatalogAdapter,
  getTopicCatalogAdapter,
} from './adapters';
import {
  IBody,
  IEdit,
  IResponseGet,
  IParams,
  IResponseGetTopicsByLevel,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';

export const topicCatalogApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopicsCatalog: builder.query({
      query: ({ limit, offset, name }) => ({
        url: endpoints.topics,
        params: {
          limit,
          offset,
          name,
        },
      }),
      providesTags: [tags.topicsCatalog],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        data: IResponseGetTopicCatalogAdapter
      ): IResponseGetTopicCatalog => getTopicCatalogAdapter(data),
    }),

    getAllTopics: builder.query<IResponseGetTopicsByLevel, IParams>({
      query: ({ limit, offset, name, childrenExist }) => ({
        url: endpoints.topics,
        params: {
          limit,
          offset,
          name,
          children_exist: childrenExist,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,

      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(({ id }) => ({ type: tags.topic, id } as const)),
            { type: tags.topic, id: 'TOPIC_LIST' },
          ];
        } else {
          return [{ type: tags.topic, id: 'TOPIC_LIST' }];
        }
      },
    }),

    getTopicsByLevel: builder.query<IResponseGetTopicsByLevel, IParams>({
      query: ({ limit, offset, level }) => ({
        url: endpoints.topics,
        params: {
          limit,
          offset,
          level,
        },
      }),
      providesTags: [tags.topicsCatalog],
    }),

    getTopic: builder.query<IResponseGet, number>({
      query: (id) => ({
        url: endpoints.topic,
        params: {
          topic_id: id,
        },
      }),
      providesTags: [tags.topicsCatalog],
    }),

    createTopic: builder.mutation<IResponseGet, IBody>({
      query: (body) => ({
        url: endpoints.topic,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [tags.topicsCatalog],
    }),

    editTopic: builder.mutation<IResponseGet, IEdit>({
      query: (body) => ({
        url: endpoints.topic,
        method: 'PUT',
        data: { payload: body.payload },
        params: {
          topic_id: body.id,
        },
      }),
      invalidatesTags: [tags.topicsCatalog],
    }),

    deleteTopic: builder.mutation({
      query: (id) => ({
        url: `${endpoints.topic}?topic_id=${id}`,
        method: 'DELETE',
        id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: tags.topicsCatalog, id: arg },
      ],
    }),
  }),
});

export const {
  useGetTopicsCatalogQuery,
  useGetAllTopicsQuery,
  useGetTopicsByLevelQuery,
  useLazyGetTopicsCatalogQuery,
  useCreateTopicMutation,
  useEditTopicMutation,
  useDeleteTopicMutation,
  useGetTopicQuery,
} = topicCatalogApi;
