import { useFormikContext } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { DateInput } from '../../DateInput';
import { ISupermixProfitValues } from 'pages/Navbar/Dashboards/SupermixProfitId/helpers';
import { SelectWrapper } from 'widgets/SelectWrapper';

import style from './MonthSelect.module.scss';

export const MonthSelect = ({
  field,
  isError,
}: {
  field: string;
  isError?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [startDate, setStartDate] = useState<Date>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const { setFieldValue } = useFormikContext<ISupermixProfitValues>();

  const maxDate = moment().subtract(1, 'months').endOf('month').toDate();

  useEffect(() => {
    if (startDate) {
      setFieldValue(field, startDate);
    }
  }, [startDate, field, setFieldValue]);

  return (
    <SelectWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isValueVisible={isValueVisible}
      title="Период"
      isError={isError}
      value={selectedDate}
      className={style.select}
    >
      <div className={style.datePickerInner}>
        <DateInput
          onChange={(date) => setStartDate(date)}
          selected={startDate}
          title="Выберите месяц"
          dateFormat="yyyy, MMM"
          showMonthYearPicker
          placeholderText="Месяц"
          maxDate={maxDate}
        />

        <button
          className={style.button}
          type="submit"
          onClick={() => {
            setIsValueVisible(true);
            setIsOpen(false);
            if (startDate) {
              setSelectedDate(
                `${startDate?.getFullYear()}, ${startDate?.toLocaleString(
                  'ru-RU',
                  {
                    month: 'long',
                  }
                )}`
              );
            }
          }}
        >
          Применить
        </button>
      </div>
    </SelectWrapper>
  );
};
