import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { saveAs } from 'file-saver';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { DateSelect, EntitySelect } from '../../DashboradsComponents';
import { getOwnersForSelect } from '../../helpers';
import { validationSchemaGetSummaryReport } from '../../Summary/helpers';
import { IfraimeTable } from '../../SupermixProfitId/SupermixProfitComponents';
import { TotalReportValues, getDate, initialValues } from '../helpers';
import { useGetOwnerByConditionQuery } from 'app/api/services/owners/owners';
import { useLazyGetTotalReportQuery } from 'app/api/services/reports';
import { errorToast } from 'utils';
import {
  DownloadSpreadsheet,
  ErrorAlert,
  LastRefreshed,
  Loader,
} from 'widgets';

import style from './Total.module.scss';

export const Total = (): JSX.Element => {
  const [isValidateCheked, setIsValidateCheked] = useState(false);

  const [getTotalReport, { data, isLoading, isFetching, error }] =
    useLazyGetTotalReportQuery();

  const { data: owners } = useGetOwnerByConditionQuery({
    offset: 0,
    limit: 1000,
  });

  const onSubmit = (values) => {
    getTotalReport({
      start_date: format(values.start_date, 'yyyy-MM-dd HH:mm:ss'),
      end_date: format(values.end_date, 'yyyy-MM-dd HH:mm:ss'),
      owner_id: values.owner?.id,
    });
  };

  const downloadFile = async (values) => {
    if (!data?.response?.download_url) return;

    const res = await fetch(data?.response?.download_url);
    const blob = await res.blob();
    if (!blob) return;

    const ext = 'xlsx';

    const fileName = `total_${getDate(values.start_date)}_${getDate(
      values.end_date
    )}.${ext}`;

    saveAs(blob, fileName);
  };

  const url =
    data?.response?.view_url +
    `/pubhtml?gid=${data?.response?.gids[0]}&chrome=false`;

  const ownersOptions = getOwnersForSelect(owners?.response);

  useEffect(() => {
    const message = (error as { data: { message: string } })?.data?.message;

    if (error) {
      errorToast(!!error, message);
    }
  }, [error]);

  return (
    <div className={style.wrapper}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchemaGetSummaryReport}
        validateOnBlur={isValidateCheked}
        validateOnChange={isValidateCheked}
      >
        {(formik) => {
          if (Object.keys(formik.errors).length) {
            setIsValidateCheked(true);
          }

          return (
            <Form
              onKeyDown={(event) =>
                event.key === 'Enter' && event.preventDefault()
              }
            >
              <div className={style.header}>
                <div className={style.left}>
                  <h2 className={style.title}>Итоговая таблица</h2>
                  <div className={style.selects}>
                    <EntitySelect
                      field={TotalReportValues.owner}
                      options={ownersOptions}
                      title="Правообладатель"
                      isDisabled={false}
                      searchEntity="owners"
                      className={style.ownersSelect}
                    />
                    <DateSelect showTimeSelect dateFormat="yyyy.MM.dd HH:mm" />
                  </div>
                </div>

                <div className={style.right}>
                  {data?.response?.created_at && (
                    <LastRefreshed
                      date={format(
                        new Date(data?.response?.created_at),
                        'd MMMM, HH:mm',
                        { locale: ru }
                      )}
                    />
                  )}
                  <DownloadSpreadsheet
                    onClick={() => downloadFile(formik.values)}
                  />
                </div>
              </div>
              {Object.keys(formik.errors).length > 0 && (
                <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
              )}
            </Form>
          );
        }}
      </Formik>

      {isLoading || isFetching ? (
        <Loader />
      ) : (
        data?.response?.view_url && (
          <div className={style.table}>
            <IfraimeTable url={url} width={'1340px'} height={'550px'} />
          </div>
        )
      )}
    </div>
  );
};
