import {
  IAddTagForCompilationMutation,
  IAddTagsForCompilationsResponse,
  ITagsForCompilationsAncestorsResponse,
  ITagsGroupApiResponse,
  IUpdateTagForCompilationMutation,
} from './types';
import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { tags } from '../../../../shared/api/tags/tags';
import { mainApi } from '../config';
import {
  getTagCatalogAdapter,
  IResponseGetTagCatalog,
  IResponseGetTagCatalogAdapter,
} from '../tagCatalog/adapters';

export const TagForCompilationsApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTagGroupsForCompilations: builder.query<ITagsGroupApiResponse, unknown>({
      query: () => ({
        url: endpoints.tagForCompilationsGroups,
      }),
      providesTags: [tags.tagForCompilations],
    }),

    getTagsForCompilations: builder.query({
      query: ({ offset, limit, name, cache }) => ({
        url: endpoints.tagsForCompilations,
        params: {
          offset,
          limit,
          name,
          cache,
        },
      }),
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (
        data: IResponseGetTagCatalogAdapter
      ): IResponseGetTagCatalog => getTagCatalogAdapter(data),

      providesTags: [tags.tagForCompilations],
    }),

    getAllTagsForCompilations: builder.query({
      query: ({ offset, limit, name, cache }) => ({
        url: endpoints.tagsForCompilations,
        params: {
          offset,
          limit,
          name,
          cache,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,

      merge(currentCacheData, responseData) {
        const newResponses = responseData.response.filter(
          (newResponse) =>
            !currentCacheData.response.some(
              (currentResponse) => currentResponse.id === newResponse.id
            )
        );
        currentCacheData.response.push(...newResponses);
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },

      providesTags: (result) => {
        if (result) {
          const { response } = result;

          return [
            ...response.map(
              ({ id }) => ({ type: tags.tagForCompilations, id } as const)
            ),
            { type: tags.tagForCompilations, id: 'TAG_COMPILATION_LIST' },
          ];
        } else {
          return [
            { type: tags.tagForCompilations, id: 'TAG_COMPILATION_LIST' },
          ];
        }
      },
    }),

    getTagByIdWithAncestorsForCompilations: builder.query<
      ITagsForCompilationsAncestorsResponse,
      number
    >({
      query: (tag_id) => ({
        url: endpoints.tagForCompilationsAncestors,
        params: {
          tag_id,
        },
      }),
      providesTags: [tags.tagForCompilations],
    }),

    getTagByIdForCompilations: builder.query({
      query: (tag_id) => ({
        url: endpoints.tagForCompilation,
        params: {
          tag_id,
        },
      }),
      providesTags: [tags.tagForCompilations],
    }),
  }),
});

export const TagForCompilationsMutation = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    AddTagForCompilations: builder.mutation<
      IAddTagsForCompilationsResponse,
      IAddTagForCompilationMutation
    >({
      query: (body) => ({
        url: endpoints.tagForCompilation,
        data: body,
        method: 'POST',
      }),
      invalidatesTags: [tags.tagForCompilations],
    }),

    UpdateTagForCompilationMutation: builder.mutation<
      IAddTagsForCompilationsResponse,
      IUpdateTagForCompilationMutation
    >({
      query: (body) => ({
        url: endpoints.tagForCompilation,
        data: { payload: body.payload },
        params: {
          tag_id: body.tag_id,
        },
        method: 'PUT',
      }),
      invalidatesTags: [tags.tagForCompilations],
    }),

    deleteTagForCompilationMutation: builder.mutation<number, number>({
      query: (id) => ({
        url: `${endpoints.tagForCompilation}?tag_id=${id}`,
        id,
        method: 'DELETE',
      }),
      invalidatesTags: [tags.tagForCompilations],
    }),
  }),
});

export const {
  useGetTagGroupsForCompilationsQuery,
  useGetTagsForCompilationsQuery,
  useGetAllTagsForCompilationsQuery,
  useGetTagByIdWithAncestorsForCompilationsQuery,
  useGetTagByIdForCompilationsQuery,
} = TagForCompilationsApiQuery;

export const {
  useAddTagForCompilationsMutation,
  useUpdateTagForCompilationMutationMutation,
  useDeleteTagForCompilationMutationMutation,
} = TagForCompilationsMutation;
