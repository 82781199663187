import React from 'react';

import style from './IfraimeTable.module.scss';
interface IProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  url: string;
}

export const IfraimeTable = ({ url, ...props }: IProps): JSX.Element => (
  <iframe className={style.iframe} {...props} src={url} scrolling="no" />
);
