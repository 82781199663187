import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetFeedGroupByConditionQuery } from '../../../../../../app/api/services/feedGroups/feedGroups';
import { useGetFeedByConditionQuery } from '../../../../../../app/api/services/feeds/feeds';
import { ICreateBaseSection } from '../../../../../../app/api/services/sections/baseSectionsTypes';
import {
  useCreateBaseSectionMutation,
  useDeleteBaseSectionMutation,
  useGetBaseSectionByIdQuery,
  useUpdateBaseSectionMutation,
} from '../../../../../../app/api/services/sections/sections';
import { Characteristics } from '../../../../../../components/Characteristics';
import { HeaderBlock } from '../../../../../../components/HeaderBlock';
import { Router } from '../../../../../../components/Router/routerEnum';
import { useAppDispatch } from '../../../../../../hooks';
import { setRubricList } from '../../../../../../store/slices/rubricSlice';
import {
  dateConverter,
  errorToast,
  successToast,
} from '../../../../../../utils';
import { getDiffObjects } from '../../../../../../utils/diffObjects';
import {
  ErrorAlert,
  Loader,
  TitleWithDescription,
} from '../../../../../../widgets';
import { createObjectToSend } from '../../../../Entity/Author/CreateAuthor/AuthorFields';
import {
  Content,
  Description,
  MainParameters,
} from '../CreateSectionComponents';
import { validateCreateSection, SectionFields } from '../SectionFields';

import style from './CreateSection.module.scss';

export const CreateSection = (): JSX.Element => {
  const [isToast, setIsToast] = useState(false);
  const [isValidateCheked, setIsValidateCheked] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    data: feeds,
    isLoading: isLoadingFeeds,
    isError: isErrorFeeds,
  } = useGetFeedByConditionQuery({ limit: 5, offset: 0, is_related: false });

  const {
    data: feedsGroup,
    isLoading: isLoadingFeedsGroup,
    isError: isErrorFeedsGroup,
  } = useGetFeedGroupByConditionQuery({ limit: 5, offset: 0 });

  const [createSection, { data, isLoading, isError, isSuccess }] =
    useCreateBaseSectionMutation();

  const [
    updateSection,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
    },
  ] = useUpdateBaseSectionMutation();

  const [
    deleteSection,
    {
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      isSuccess: isSuccessDelete,
    },
  ] = useDeleteBaseSectionMutation();

  const {
    data: baseSection,
    isLoading: isLoadingGetById,
    isFetching: isFetchingGetById,
  } = useGetBaseSectionByIdQuery(Number(id), {
    skip: !id,
  });

  const onClickDelete = () => {
    deleteSection(Number(id));
  };

  const isEdit = !!id;

  const initialValues: ICreateBaseSection = {
    [SectionFields.Name]: baseSection?.response?.name,
    [SectionFields.Is_active]: isEdit
      ? baseSection?.response?.is_active
      : false,
    [SectionFields.Primary_image]: baseSection?.response?.primary_image_url,
    [SectionFields.Secondary_image]: baseSection?.response?.secondary_image_url,
    [SectionFields.Primary_banner]: baseSection?.response?.primary_banner_url,
    [SectionFields.Secondary_banner]:
      baseSection?.response?.secondary_banner_url,
    [SectionFields.Elements]: [],
    [SectionFields.Weight]: isEdit ? baseSection?.response?.weight : 0,
    [SectionFields.Published_at]: baseSection?.response?.published_at,
    // [SectionFields.Feed_bottom]: undefined,
    [SectionFields.Images]: {},
  };

  useEffect(() => {
    if (baseSection?.response?.elements) {
      // const rubricList = Object.assign([], item?.response?.elements).sort(
      //   (a, b) => a.position - b.position
      // );
      dispatch(setRubricList(baseSection?.response?.elements));
    }
    if (location.pathname === `/${Router.Rubric}/${Router.Create}`) {
      dispatch(setRubricList([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseSection?.response?.elements, location]);

  useEffect(() => {
    successToast({
      success: isSuccess && !isEdit,
      message: 'Раздел успешно создан',
      setIsToast,
      navigate,
      path: `/${Router.Section}/base/${Router.Edit}/${data?.response?.id}`,
    });

    successToast({
      success: isSuccessEdit && !isSuccessDelete,
      message: 'Изменения внесены успешно',
      setIsToast,
    });

    successToast({
      success: isSuccessDelete,
      message: 'Раздел успешно удален',
      setIsToast,
      navigate,
      path: `/${Router.Section}`,
    });
  }, [
    isSuccess,
    isEdit,
    navigate,
    isSuccessEdit,
    isSuccessDelete,
    data?.response?.id,
  ]);

  useEffect(() => {
    errorToast(isError, 'Не удалось создать раздел', setIsToast);
    errorToast(isErrorEdit, 'Не удалось внести изменения', setIsToast);
    errorToast(isErrorDelete, 'Не удалось удалить раздел', setIsToast);
    errorToast(
      isErrorFeeds || isErrorFeedsGroup,
      'Не удалось загрузить справочники. Обновите страницу'
    );
  }, [isError, isErrorEdit, isErrorDelete, isErrorFeeds, isErrorFeedsGroup]);

  return (
    <div className={style.container}>
      {(isLoadingFeeds ||
        isLoadingFeedsGroup ||
        isLoading ||
        isLoadingEdit ||
        isLoadingGetById ||
        isLoadingDelete ||
        isFetchingGetById) &&
      !isToast ? (
        <Loader />
      ) : null}
      {!isLoadingGetById && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={(e) => validateCreateSection(e)}
          validateOnBlur={isValidateCheked}
          validateOnChange={isValidateCheked}
          onSubmit={(values) => {
            const diff = getDiffObjects(initialValues, values);
            const valuesToSend = createObjectToSend(
              diff,
              initialValues,
              values
            );

            isEdit
              ? updateSection({
                  id: Number(id),
                  values: valuesToSend as unknown as ICreateBaseSection,
                })
              : createSection(values);
          }}
        >
          {(formik) => {
            const disabled = false;

            if (Object.keys(formik.errors).length) {
              setIsValidateCheked(true);
            }

            return (
              <Form
                className={style.form}
                onKeyDown={(event) =>
                  event.key === 'Enter' && event.preventDefault()
                }
              >
                <HeaderBlock
                  disabled={disabled}
                  deleteEntity={onClickDelete}
                  deleteBtnEntity="раздел"
                  editEntity="раздела"
                />

                <Description />

                <Content feeds={feeds} feedsGroup={feedsGroup} />

                <MainParameters />

                <Characteristics
                  titleUUID="UUID раздела:"
                  titleDateCreated="Дата создания:"
                  UUIDDescription={baseSection?.response?.uuid}
                  dateCreatedDescription={baseSection?.response?.created_at}
                >
                  <TitleWithDescription
                    title="Дата обновления:"
                    description={dateConverter(
                      baseSection?.response?.updated_at
                    )}
                    className={cn(style.updateDate)}
                  />
                </Characteristics>
                {Object.keys(formik.errors).length > 0 && (
                  <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
                )}
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
